import React, { useState } from 'react';
import './hospital&doctormodal.css'; // Import the CSS file

const AddHospitalModal = ({ show, onClose, onSave, cityId }) => {
  const [hospitalName, setHospitalName] = useState('');

  if (!show) {
    return null;
  }

  const handleSave = () => {
    if(hospitalName.trim()==='')
        {alert("hospital Name cannot be empty")
            return
        }
    onSave(hospitalName, cityId);
    setHospitalName('');
    onClose();
  };

  return (
    <div className="modal-backdrop">
      <div className="modal-container">
        <div className="modal-header">
          <h2 className="modal-title">Add New Hospital</h2>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>
        <div className="modal-body">
          <div className="form-group">
            <label>Hospital Name:</label>
            <input
              type="text"
              value={hospitalName}
              onChange={(e) => setHospitalName(e.target.value)}
              className="modal-input"
              placeholder="Enter hospital name"
            />
          </div>
        </div>
        <div className="modal-footer">
          <button className="modal-submit" onClick={handleSave}>Save</button>
          {/* <button className="modal-cancel" onClick={onClose}>Cancel</button> */}
        </div>
      </div>
    </div>
  );
};

export default AddHospitalModal;
