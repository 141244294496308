import React from 'react';
import './HelpModal.css'; // Ensure your styles are in this file

const HelpModal = ({ onClose }) => {
    return (
        <div className="modal-backdrop">
            <div className="modal-content">
                <div className="modal-header">
                    <h2 style={{margin:"0px"}}>Need Help?</h2>
                    <button onClick={onClose} className="close-button">×</button>
                </div>
                <div className="modal-body">
                    <p>If you need help, here are some common questions and answers:</p>
                    <ul>
                        <li>How do I create an entry? - Go to the 'New Entry' tab and fill out the form.</li>
                        <li>Where can I view my submissions? - All submissions are under the 'View Submissions' tab.</li>
                        <li>Who do I contact for further questions? - Please contact our support at support@example.com.</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default HelpModal;
