import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './DashboardPage.css';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import SalesOverviewCard from '../src/cards/SalesOverviewCard';

const DashboardPage = () => {
    const [salesEntries, setSalesEntries] = useState([]);
    const [error, setError] = useState('');
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    useEffect(() => {
        const fetchSalesEntries = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/sales`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setSalesEntries(response.data);
                console.log("responsetodashboard", response.data);
            } catch (error) {
                setError('Failed to fetch sales entries');
                console.error('Error:', error);
            }
        };

        fetchSalesEntries();
    }, []);

    // Calculate total sales, total amount, and total tests
    const totalSales = salesEntries.length;
    const totalAmount = salesEntries.reduce((total, entry) => total + (parseFloat(entry.Amount) || 0), 0);
    const totalTests = salesEntries.reduce((total, entry) => total + (entry.tests?.length || 0), 0);

    const getFilteredData = () => {
        return salesEntries.filter(entry => new Date(entry.createdAt).getFullYear().toString() === selectedYear);
    };

    const calculateMonthlySales = (filteredEntries) => {
        const monthlySalesCount = {};
        for (let entry of filteredEntries) {
            const date = new Date(entry.createdAt);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const monthKey = `${year}-${month < 10 ? '0' + month : month}`;
            if (!monthlySalesCount[monthKey]) {
                monthlySalesCount[monthKey] = 0;
            }
            monthlySalesCount[monthKey]++;
        }
        return monthlySalesCount;
    };

    const years = [...new Set(salesEntries.map(entry => new Date(entry.createdAt).getFullYear()))];

    const handleChangeYear = (event) => {
        setSelectedYear(event.target.value);
    };

    const filteredEntries = getFilteredData();
    const monthlySalesCount = calculateMonthlySales(filteredEntries);
    const sortedMonths = Object.keys(monthlySalesCount).sort();
    const chartData = {
        labels: sortedMonths,
        datasets: [{
            label: 'Monthly Sales Count',
            data: sortedMonths.map(month => monthlySalesCount[month]),
            backgroundColor: 'rgba(0, 191, 255, 0.2)',  // Light green with some transparency
            borderColor: 'rgba(75, 192, 192, 1)',  // Solid light green border
            borderWidth: 1
        }]
    };

    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom'
            }
        },
        responsive: true,
        maintainAspectRatio: false
    };

    return (
        <div className="dashboard-container">
            {/* <h1>Dashboard</h1> */}
          
            {/* Insert the Sales Overview Card */}
            <SalesOverviewCard 
                totalSales={totalSales} 
                totalTests={totalTests} 
                totalAmount={totalAmount} 
            />
              {error && <p className="error">{error}</p>}
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '8px' }}>
                <span style={{ paddingRight: '10px', paddingTop: '5px' }}>Select Year</span>
                <select value={selectedYear} onChange={handleChangeYear} style={{ padding: '5px', borderRadius: '4px', border: '1px solid #ccc', background: 'white' }}>
                    {years.map(year => (
                        <option key={year} value={year}>{year}</option>
                    ))}
                </select>
            </div>
            <div className="metrics">
                <div style={{ height: '400px' }}>
                    <Bar data={chartData} options={chartOptions} />
                </div>
            </div>
        </div>
    );
};

export default DashboardPage;
