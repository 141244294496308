import React from 'react';

function AboutPage() {
    return (
        <div>
            <h1>About</h1>
            <p>Welcome to the AboutME! This is the central panel where you can get an overview of the application's activities.</p>
        </div>
    );
}

export default AboutPage;
