import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Assuming you have some basic styles in NavBar.css
import LogoutButton from './LogoutButton';

function Navbar({ isAuthenticated, onLogout }) {
    const userRole=localStorage.getItem('role');
    
    return (
       
        <nav className="navbar" >
            {userRole==='lab'?( 
            <>
          <Link to="/hospital-portal">Test Entries</Link>
          <Link to="/help">Help</Link>
            </>
        ):
        userRole==='admin'?
        (
        <>
        <Link to="/dashboardPage">Dashboard</Link>
        <Link to="/sales-details">Sales</Link>
        <Link to="/about" className="about-link">About</Link>
        <Link to="/hospital-portal">Lab Section</Link>
        </>
    ):
    userRole==='sales_rep'?(
        <>
        <Link to="/sales-details">Sales</Link>
        <Link to="/about" className="about-link">About</Link>
        </>
    ):null
}
               
            {isAuthenticated && <LogoutButton onLogout={onLogout} />}
            
           
        </nav>
    );
}

export default Navbar;
