// src/component/EmailModal.js
import React, { useState,useEffect } from 'react';
import Modal from 'react-modal';
import styles from './HospitalEmailModal.module.css';
import Loader from './Loader';


Modal.setAppElement('#root');

function HospitalEmailModal({ isOpen, onRequestClose, test, SendEmail }) {
  const [recipientEmail, setRecipientEmail] = useState(''); // State for managing recipient email input
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e) => {
    setRecipientEmail(e.target.value); // Handle change in email input
  };

  const handleSubmit = async () => {
    setLoading(true);
    // Gather all report URLs from tests
    const reportUrls = test.map(test => test.reportUrl).filter(url => url); // Filter to include only valid URLs
    await SendEmail(recipientEmail, test, reportUrls); // Send email with report URLs
    setLoading(false);
    onRequestClose(); // Close the modal
    
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles["modal-content"]}
      overlayClassName="modal-overlay"
    >
      {loading && <div className="loader"></div>}
      <h2>Send Email</h2>
      <button className={styles['close-button']} onClick={onRequestClose}>×</button>
      <hr />
      <form className="modal-form">
        <div className="form-group">
          <label>Recipient Email:</label>
          <input 
            type="email" 
            value={recipientEmail} 
            onChange={handleEmailChange} 
            required 
          />
        </div>
        <div className={styles['form-group']}>
          <label>Reports</label>
          {test.length > 0 ? (
            test.map((test, index) => (
              <div key={index}>
                {test.reportUrl ? (
                  <a href={test.reportUrl} target="_blank" rel="noopener noreferrer">
                    View Report {index + 1} - {test.test}
                  </a>
                ) : (
                  <p>No report uploaded for {test.test}.</p>
                )}
              </div>
            ))
          ) : (
            <p>No reports available.</p>
          )}
        </div>
      </form>
      <button className={styles['submit-button']} disabled={loading}  onClick={handleSubmit} style={{cursor:loading?'not-allowed':'pointer',opacity:loading?0.6:1}}>Send</button>
    </Modal>
  );
}

export default HospitalEmailModal;
