import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './SampleModal.css'

import { ToastContainer, toast, cssTransition } from "react-toastify";
Modal.setAppElement('#root'); // Ensure accessibility

function SampleModal({ isOpen, onRequestClose, test, handleSampleReceivedStatus, readOnly }) {
  const [sampleReceivedStatus, setSampleReceivedStatus] = useState(test.sampleReceivedStatus || "Pending");
  const [sampleReceivedDate, setSampleReceivedDate] = useState(test.sampleReceivedDate || "");
  const [sampleReceivedTime, setSampleReceivedTime] = useState(test.sampleReceivedTime || "");
  const [rejectionReason, setRejectionReason] = useState(test.rejectionReason || "");
  const [expectedReportDate,setExpectedReportDate]=useState(test.expectedReportDate||"");


  useEffect(() => {
    setSampleReceivedStatus(test.sampleReceivedStatus || "Pending");
    setSampleReceivedDate(test.sampleReceivedDate || "");
    setRejectionReason(test.rejectionReason || "");
    setSampleReceivedTime(test.sampleReceivedTime || "");
    setExpectedReportDate(test.expectedReportDate||"");
  }, [test]);

  const handleStatusChange = (e) => {
    setSampleReceivedStatus(e.target.value);
  };

  const handleDateChange = (e) => {
    setSampleReceivedDate(e.target.value);
  };

  const handleTimeChange = (e) => {
    setSampleReceivedTime(e.target.value);
  };
  const handleRejectionReasonChange = (e) => {
    setRejectionReason(e.target.value);
  };
  const handleExpecteReportDate = (e) => {
    setExpectedReportDate(e.target.value);
  };

  const handleSubmit = () => {

    if(sampleReceivedStatus==="Pending"){
      toast.error("Please Select Sample Recieved Status")
      return
    }
    if(sampleReceivedDate===""){toast.error("Please enter Sample Recieved Date") 
      return
    }
    if(sampleReceivedTime===""){toast.error("Please enter Sample Recieved Time")
       return}
    if(expectedReportDate===""){toast.error("Please enter Expected Report date") 
      return}


    handleSampleReceivedStatus(test.id, {
      status: sampleReceivedStatus,
      date: sampleReceivedDate,
      time: sampleReceivedTime,
      expectedReportDate:expectedReportDate,
      rejectionReason:rejectionReason
    });
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal-content"
      overlayClassName="modal-overlay"
    >
       <ToastContainer position="bottom-right" autoClose={5000} />
      <h2>Sample Received Details</h2>
      <button className="close-button" onClick={onRequestClose}>×</button>
      <form className="modal-form">
        <div className="form-group">
          <label>Sample Received Date:</label>
          <input type="date" value={sampleReceivedDate} onChange={handleDateChange} disabled={readOnly} />
        </div>
        <div className="form-group">
          <label>Sample Received Time:</label>
          <input type="time" value={sampleReceivedTime} onChange={handleTimeChange} disabled={readOnly} />
        </div>
        <div className="form-group">
          <label>Status:</label>
          <select value={sampleReceivedStatus} onChange={handleStatusChange} disabled={readOnly}>
            <option value="Pending">Pending</option>
            <option value="Received & Accepted">Received & Accepted</option>
            <option value="Received & Rejected">Received & Rejected</option>
          </select>
        </div>
        {sampleReceivedStatus === "Received & Rejected" && (
          <div className="form-group">
            <label>Reason for Rejection:</label>
            <textarea value={rejectionReason} onChange={handleRejectionReasonChange} disabled={readOnly}></textarea>
            </div>
      )}
      {(sampleReceivedStatus === "Received & Accepted"|| sampleReceivedStatus === "Received & Rejected") && (
          <div className="form-group">
            <label >Expected Report date:</label>
            <input type="date"
             id="expectedReportDate" 
             value={expectedReportDate} 
             onChange={handleExpecteReportDate} 
             disabled={readOnly} />
            {/* <textarea value={expectedReportDate} onChange={handleExpecteReportDate} disabled={readOnly}></textarea> */}
            </div>
      )}
      </form>
      {!readOnly && <button className="submit-button" onClick={handleSubmit}>Submit</button>}
    </Modal>
  );
}

export default SampleModal;
