import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const FloatingHelpButton = ({ onHelpClick }) => {
    return (
        <button 
            onClick={onHelpClick}
            style={{
                position: 'fixed',
                bottom: '25px',
                right: '20px',
                padding: '10px 15px',
                fontSize: '20px',
                color: '#fff',
                backgroundColor: '#007bff',
                border: 'none',
                borderRadius: '50%',
                cursor: 'pointer',
                boxShadow: '0 2px 5px rgba(0,0,0,0.3)'
            }}
            aria-label="Help"
        >
            <FontAwesomeIcon icon={faQuestionCircle} />
        </button>
    );
};

export default FloatingHelpButton;
