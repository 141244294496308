import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import styles from './TestStatusModal.module.css';
import { ToastContainer, toast, cssTransition } from "react-toastify";

Modal.setAppElement('#root'); // Ensure accessibility

function TestStatusModal({ isOpen, onRequestClose, test, handleTestStatus, handleFileUpload, readOnly }) {
  const [testStatus, setTestStatus] = useState(test.testStatus || "Pending");
  const [rejectionReason, setRejectionReason] = useState(test.rejectionReason || "");
  const [reportUrl, setReportUrl] = useState(test.reportUrl || "");
  const [fileName, setFileName] = useState('');
  const [isUploadingAgain, setIsUploadingAgain] = useState(false);
  const [resonforReupload, setUploadReason] = useState('');
  const [hasUploaded, setHasUploaded] = useState(false); 

  function getFileNameFromUrl(url) {
    // Extract the part after the last '/' to get the file name
    return url.substring(url.lastIndexOf('/') + 1).split('?')[0]; // Remove any query parameters
  }

  useEffect(() => {
    setTestStatus(test.testStatus || "Pending");
    setRejectionReason(test.rejectionReason || "");
    setReportUrl(test.reportUrl || "");
    setUploadReason(test.resonforReupload || "");
    setIsUploadingAgain(false); 
    setHasUploaded(false);
    if (test.reportUrl) {
      setFileName(getFileNameFromUrl(test.reportUrl));
    } else {
      setFileName("");
    }
  }, [test]);

  const handleStatusChange = (e) => {
    setTestStatus(e.target.value);
  };

  const handleRejectionReasonChange = (e) => {
    setRejectionReason(e.target.value);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const uploadedFileUrl = await handleFileUpload(test.id, file);
      setReportUrl(uploadedFileUrl);
      setFileName(file.name);
      console.log("file", file);
      setIsUploadingAgain(true); // Set to true when a new file is uploaded
      setHasUploaded(true);
    }
  };

  const handleSubmit = () => {
    console.log("resonforReupload submit", resonforReupload);
    console.log("testStatus",testStatus)

    if(testStatus==="Pending"){
      toast.error("Test status is mandatory");
      return
    }
    if(reportUrl===""){
      toast.error("Please upload test Report");
      return
    }
    handleTestStatus(test.id, {
      status: testStatus,
      rejectionReason,
      resonforReupload,
    });
    onRequestClose();
  };

  const handleUploadReasonChange = (e) => {
    setUploadReason(e.target.value);
    console.log("reason for reupload", e.target.value);
    console.log("resonforReupload", resonforReupload);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="modal-content"
      overlayClassName="modal-overlay"
    >
        <ToastContainer position="bottom-right" autoClose={5000} />
      <h2 style={{ marginTop: '0px', marginBottom: '0px' }}>Test Status</h2>
      <hr style={{ marginTop: '0px' }}></hr>
      <button className={styles['close-button']} onClick={onRequestClose}>×</button>
      <form className="modal-form">
        <div className="form-group">
          <label>Status:</label>
          <select value={testStatus} onChange={handleStatusChange} disabled={readOnly}>
            <option value="Pending">Pending</option>
            <option value="Accepted">Accepted</option>
            <option value="Rejected">Rejected</option>
          </select>
        </div>
        {testStatus === "Rejected" && (
          <div className="form-group">
            <label>Reason for Rejection:</label>
            <textarea value={rejectionReason} onChange={handleRejectionReasonChange} disabled={readOnly}></textarea>
          </div>
        )}
        <div className="form-group">
          <label>Upload Report:</label>
          <input type="file" onChange={handleFileChange} />
          {reportUrl && (
            <div>
              <a href={reportUrl} target="_blank" rel="noopener noreferrer">View Uploaded Report - {fileName}</a>
            </div>
          )}
        </div>

        {/* Show reason input only if a report was previously uploaded */}
        {isUploadingAgain && (
          <div className="form-group">
            <label>note:</label>
            <textarea
              value={resonforReupload}
              onChange={handleUploadReasonChange}
              required
            />
          </div>
        )}
     
      
      </form>
      <button
        className={styles['submit-button']}
        onClick={handleSubmit}
        // disabled={readOnly || (hasUploaded && !resonforReupload.trim())} // Disable if reason is empty on subsequent uploads
      >
        Submit
      </button>
    </Modal>
  );
}

export default TestStatusModal;
