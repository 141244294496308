import React, { useState} from "react";
import Modal from 'react-modal';
import styles from './TestResultModal.module.css';

function TestResultModal({isOpen,onRequestClose,test,handleSampleResult}){

    const [result, setResult]=useState(test.testResult||"");
    const[values,setValues]=useState(test.testValues||"");
  
    const[antibodies,setAntibodies]=useState(test.testAntibodies||"");

    const handleSubmit=()=>{
        handleSampleResult(test.id,{result,values,antibodies});
        console.log("handle submit",result,values,antibodies);
        onRequestClose();

    };

  // useEffect(()=>{
  //   if(test){
  //     setResult(test.testResult || "");
  //     setValues(test.testValues || "");
  //     setAntibodies(test.testAntibodies || "");
  //   }
  // },[test])
  console.log("test from hospital email modal",test)

    return (
        <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          contentLabel="Test Result Modal"
          className={styles.modalContent}
        >
          <h2 className={styles.modalHeader}>Enter Test Result</h2>
          <button className={styles.closeButton} onClick={onRequestClose}>×</button>
          <hr></hr>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className={styles.formGroup}>
              <label>Result:</label>
              <select value={result} onChange={(e) => setResult(e.target.value)}>
                <option value="">Select</option>
               
                <option value="Positive">Positive</option>
                <option value="Negative">Negative</option>
              </select>
            </div>
            
            <div className={styles.formGroup}>
              <label>Values:</label>
              <input
                type="text"
                value={values}
                onChange={(e) => setValues(e.target.value)}
              />
            </div>
            <div className={styles.formGroup}>
              <label>Antibodies:</label>
              <input
                type="text"
                value={antibodies}
                onChange={(e) => setAntibodies(e.target.value)}
              />
            </div>
            <div className={styles.buttonGroup}>
              <button type="button" onClick={handleSubmit}>
                Save
              </button>
              <button type="button" onClick={onRequestClose}>
                Cancel
              </button>
            </div>
          </form>
        </Modal>
      );
    }
    




    export default TestResultModal;