import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './LogoutButton.css'; 

const LogoutButton = ({ onLogout }) => {
  const navigate = useNavigate();
  const [isLoggingOut, setIsLoggingOut] = useState(false); // State to handle asynchronous logout operation

  const handleLogoutClick = async () => {
    setIsLoggingOut(true); // Indicate logout process has started
    await onLogout(); // Perform the logout operation passed as a prop
    // Note: Only include await if onLogout is asynchronous and returns a Promise
    navigate('/login'); // Navigate to the login page after logout
  };

  return (
    <div className= "logout-btn-container">
    <button 
      onClick={handleLogoutClick} 
      disabled={isLoggingOut} 
      aria-label="Logout"
      className="logout-button"
  
    >
      {isLoggingOut ? 'Logging Out...' : 'Logout'}
    </button>
    </div>
  );
};

export default LogoutButton;
