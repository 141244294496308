import React, { useState } from 'react';
import './hospital&doctormodal.css';
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddDoctorModal = ({ show, onClose, onSave, hospitalId }) => {
  const [doctorName, setDoctorName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');

  if (!show) {
    return null;
  }

  const handleSave = () => {
    if(doctorName.trim()==='')
        {
            alert("Doctor Name Cannot be Empty")
            return
        }

        if(mobileNumber){
          if(mobileNumber.length!==10){
            alert("Mobile Number must be 10 digit")
            return
          }
        
        }
        
        

        if(email){
          const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          if (!emailPattern.test(email)) {
            alert("Please enter a valid email address.");
            return;
        }
      }
    onSave(doctorName, hospitalId, mobileNumber, email);
    setDoctorName('');
    setMobileNumber('');
    setEmail('');
    onClose();
  };

  return (
    <div className="modal-backdrop">
      <ToastContainer position="bottom-right" autoClose={5000} />
      <div className="modal-container">
        <div className="modal-header">
          <h2 className="modal-title">Add New Doctor</h2>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>
        <div className="modal-body">
          <div className="form-group">
            <label>Doctor Name:</label>
            <input
              type="text"
              value={doctorName}
              onChange={(e) => setDoctorName(e.target.value)}
              className="modal-input"
              placeholder="Enter doctor's name"
            />
          </div>
          <div className="form-group">
            <label>Mobile Number:</label>
            <input
              type="text"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              className="modal-input"
              placeholder="Enter mobile number"
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="modal-input"
              placeholder="Enter email address"
            />
          </div>
        </div>
        <div className="modal-footer">
          <button className="modal-submit" onClick={handleSave}>Save</button>
          {/* <button className="modal-cancel" onClick={onClose}>Cancel</button> */}
        </div>
      </div>
    </div>
  );
};

export default AddDoctorModal;
