import React, { useState, useEffect  } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import LoginPage from './LoginPage';
import SalesDetailsPage from './SalesDetailsPage';
import LogoutButton from './LogoutButton'; // Ensure this import is correct
import Navbar from './Navbar'; // Import the NavBar component
// import About from './About'; // Make sure you have an AboutPage component
// import About from './About.js';
import DashboardPage from './DashboardPage'; 
import AboutPage from './AboutPage';
import SalesEntryForm from './SalesEntryForm'; 
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify'; // Import ToastContainer
import HospitalPortal from './HospitalPortal';
// import 'bootstrap/dist/css/bootstrap.min.css';



// const REACT_APP_API_BASE_URL="http://prod-crmbackend.ap-south-1.elasticbeanstalk.com"

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));
  const [userRole, setUserRole] = useState(localStorage.getItem('role') || '');

 
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
     
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`; // Ensure headers are set on initial load
    }
  }, []);
  
  const handleLogin = async (username, password) => {
    try {
      console.log('API Base URL:', process.env.REACT_APP_API_BASE_URL);
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/users/login`, {
        username, 
        password,
      });
      
      if (response.data.token) {
        setIsAuthenticated(true);
        setUserRole(response.data.role);
        console.log("response.data.role",response.data.role)
        localStorage.setItem('token', response.data.token); // Save token for future requests
        localStorage.setItem('role', response.data.role);//save role in local
        localStorage.setItem('regionalCodes', JSON.stringify(response.data.regionalCodes));
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`; // Set default Authorization header
        console.log("Login successful:", response.data.token);
        console.log("Login successful:", response.data.message);
      } else {
        // Handle case where login response might not be as expected
        throw new Error('Login failed');
      
      }
    } catch (error) {
      console.error("Login error:", error.response?.data?.message || "An error occurred");
      setIsAuthenticated(false); // Ensure user is not authenticated if login fails
      throw error;
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove the token from localStorage
    setIsAuthenticated(false); // Update the authenticated state to false
    delete axios.defaults.headers.common['Authorization'];
    localStorage.removeItem('regionalCodes')
  };

  return (
    <Router>
      <div className="App">
      {isAuthenticated && <Navbar isAuthenticated={isAuthenticated} onLogout={handleLogout} />}
        {/* {isAuthenticated && <LogoutButton onLogout={handleLogout} />} */}
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

        <Routes>
          <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
          <Route path="/" element={
            isAuthenticated 
              ? (userRole === 'lab'||'admin' ? <HospitalPortal /> : <DashboardPage />)
              : <Navigate replace to="/login" />
          } />
          <Route path="/" element={isAuthenticated ? <DashboardPage/>:<Navigate replace to="/login" />} />
          <Route path="/dashboardPage" element={isAuthenticated ? <DashboardPage/> : <Navigate replace to="/login" />} />
          <Route path="/sales-details" element={isAuthenticated ? <SalesDetailsPage /> : <Navigate replace to="/login" />} />
          <Route path="/about" element={isAuthenticated ? <AboutPage /> : <Navigate replace to="/login" />} />
          <Route path="/sales-entry" element={<SalesEntryForm />} /> // Public route for sales entry
          
          <Route path="/hospital-portal" element={isAuthenticated ? <HospitalPortal /> : <Navigate replace to="/login" />} /> {/* Protected route for hospital portal */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
