// PreviewForm.js
import React from 'react';
import './PreviewForm.css'; 

const PreviewForm = ({ formData, handleBack }) => {
  return (
    <div className="preview-form">
      <div className="preview-header">
        {/* <h2>Preview Form</h2> */}
      </div>
      <div className="preview-content">
        <div className="preview-section">
          <h3>Patient Details</h3>
          <div className="preview-details">
            <div className="preview-row"><strong>Salutation:</strong><span>{formData.PatientSalutation}</span></div>
            <div className="preview-row"><strong>Full Name:</strong><span>{formData.PatientFullName}</span></div>
            <div className="preview-row"><strong>Gender:</strong><span>{formData.PatientGender}</span></div>
            <div className="preview-row"><strong>Age:</strong><span>{formData.PatientAge}</span></div>
            <div className="preview-row"><strong>Date of Birth:</strong><span>{formData.PatientDateOfBirth}</span></div>
            <div className="preview-row"><strong>Mobile Number:</strong><span>{formData.PatientMobileNumber}</span></div>
            <div className="preview-row"><strong>Email ID:</strong><span>{formData.PatientEmailID}</span></div>
            <div className="preview-row"><strong>Hospital ID:</strong><span>{formData.PatientHospitalID}</span></div>
            <div className="preview-row"><strong>Address:</strong><span>{formData.PatientAddress}</span></div>
            <div className="preview-row"><strong>City:</strong><span>{formData.PatientCity}</span></div>
            {/* <div className="preview-row"><strong>Details:</strong><span>{formData.PatientDetails}</span></div> */}
          </div>
        </div>
        <div className="preview-section">
          <h3>Doctor & Hospital Details</h3>
          <div className="preview-details">
            <div className="preview-row"><strong>Referring Consultant (Primary Physician):</strong><span>{formData.ReferringConsultantName_PrimaryPhysician}</span></div>
            <div className="preview-row"><strong>Primary Physician Mobile Number:</strong><span>{formData.PrimaryPhysicianMobileNumber===""?"-":formData.PrimaryPhysicianMobileNumber}</span></div>
            <div className="preview-row"><strong>Primary Physician Email ID:</strong><span>{formData.PrimaryPhysicianEmailId===""?"-":formData.PrimaryPhysicianEmailId}</span></div>
            <div className="preview-row"><strong>Referring Doctor (Alternate Physician):</strong><span>{formData.ReferringDoctorName_AlternatePhysician===""?"-":formData.ReferringDoctorName_AlternatePhysician}</span></div>
            <div className="preview-row"><strong>Alternate Physician Mobile No:</strong><span>{formData.AlternativePhysicianMobileNo===""?"-":formData.AlternativePhysicianMobileNo}</span></div>
            <div className="preview-row"><strong>Alternate Physician Email ID:</strong><span>{formData.AlternativePhysicianEmailId===""?"-":formData.AlternativePhysicianEmailId}</span></div>
            <div className="preview-row"><strong>Hospital/Clinic Name:</strong><span>{formData.HospitalClinicName===""?"-":formData.HospitalClinicName}</span></div>
            <div className="preview-row"><strong>Hospital/Clinic Email ID:</strong><span>{formData.HospitalorClinicalEmailId===""?"-":formData.HospitalorClinicalEmailId}</span></div>
            <div className="preview-row"><strong>City:</strong><span>{formData.City===""?"-":formData.City}</span></div>
            <div className="preview-row"><strong>Clinical Details of Patient/Previous Diagnosis:</strong><span>{formData.ClinicalDetailsofPatient_PatientHistory_PriviousDiagonosis===""?"-":formData.ClinicalDetailsofPatient_PatientHistory_PriviousDiagonosis}</span></div>
          </div>
        </div>
        <div className="preview-section">
          <h3>Test Details</h3>
          <div className="preview-details">
            <div className="preview-row"><strong>Sample Sent:</strong><span>{formData.SampleSent}</span></div>
            <div className="preview-row"><strong>Number of Samples Sent:</strong><span>{formData.NoOfSampleSent}</span></div>
            {formData.tests.map((test, index) => (
              <div key={index} className="test-details">
                <div className="preview-row"><strong>Test {index + 1}:</strong><span>{test.test}</span></div>
                <div className="preview-row"><strong>Sample for Test {index + 1}:</strong><span>{test.sample}</span></div>
              </div>
            ))}
          </div>
        </div>
        <div className="preview-section">
          <h3>Sample Collection Details</h3>
          <div className="preview-details">
            <div className="preview-row"><strong>Sample Collection Date:</strong><span>{formData.SampleCollectionDate}</span></div>
            <div className="preview-row"><strong>Sample Collection Time:</strong><span>{formData.SampleCollectionTime}</span></div>
            <div className="preview-row"><strong>Form Filled By:</strong><span>{formData.FormFilledBy}</span></div>
            <div className="preview-row"><strong>Form Filled By (Name):</strong><span>{formData.FormFilledBy_Name===""?"-":formData.FormFilledBy_Name}</span></div>
          </div>
        </div>
        <div className="preview-section">
          <h3>Payment & Location Details</h3>
          <div className="preview-details">
            <div className="preview-row"><strong>Payment Method:</strong><span>{formData.PaymentMethod}</span></div>
            <div className="preview-row"><strong>Amount (₹):</strong><span>{formData.Amount}</span></div>
            <div className="preview-row"><strong>PickUp City:</strong><span>{formData.PickUpCity === "" ? "-" : formData.PickUpCity}</span></div>
            <div className="preview-row"><strong>PickUp Address:</strong><span>{formData.PickUpAdress===""?"-":formData.PickUpAdress}</span></div>
            <div className="preview-row"><strong>PickUp City PinCode:</strong><span>{formData.PickUpCityPinCode===""?"-":formData.PickUpCityPinCode}</span></div>
            <div className="preview-row"><strong>Contact Person Name:</strong><span>{formData.ContactPersonName===""?"-":formData.ContactPersonName}</span></div>
            <div className="preview-row"><strong>Contact Person Number:</strong><span>{formData.ContactPersonNumber===""?"-":formData.ContactPersonNumber}</span></div>
            <div className="preview-row"><strong>PickUp date:</strong><span>{formData.PickUpdate===""?"-":formData.PickUpdate}</span></div>
          </div>
        </div>
      </div>
      <div className="preview-buttons">
        <button type="button" onClick={handleBack} className="sales-form-button-back">
          Back
        </button>
        {/* <button type="submit" className="sales-form-button-submit">
          Submit
        </button> */}
      </div>
    </div>
  );
};

export default PreviewForm;
