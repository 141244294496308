// VerticalStepper.js
import React from 'react';
import './verticalstepper.css';

const steps = [
  'Patient Details',
  'Doctor & Hospital Details',
  'Test Details',
  'Sample Collection Details',
  'Payment & Location Details',
  'Preview Form & Submit',
  
];

const VerticalStepper = ({ currentStep }) => {
  const getStepHeight = (stepIndex) => {
    const stepHeight = 80; // Adjust based on your step height (including padding and margin)
    return stepHeight * stepIndex;
  };

  return (
    <div className="vertical-stepper">
      {steps.map((step, index) => {
        const stepClass = index + 1 === currentStep
          ? 'step active'
          : index + 1 < currentStep
          ? 'step completed'
          : 'step';
        const stepHeight = getStepHeight(index);

        return (
          <div key={index} className={stepClass} style={{ '--step-height': `${stepHeight}px` }}>
            <div className="step-number">{index + 1}</div>
            <div className="step-text">{step}</div>
          </div>
        );
      })}
    </div>
  );
};

export default VerticalStepper;
