// UpdatePaymentModal.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './UpdatePaymentModal.module.css'; 
import { ToastContainer, toast, cssTransition } from "react-toastify";

const UpdatePaymentModal = ({ entry, onClose, onUpdate,isReadOnly  }) => {
  const [paymentStatus, setPaymentStatus] = useState('');
  const [paymentDate, setPaymentDate] = useState('');
  const [receivedAmount, setReceivedAmount] = useState('');

  useEffect(() => {
    if (entry) {
      setPaymentStatus(entry.PaymentStatus || '');
      setPaymentDate(entry.PaymentDate || '');
      setReceivedAmount(entry.PaymentAmount || '');
    }
  }, [entry]);

  const handlePaymentSubmit = async () => {
    
    if(entry.PaymentMethod!="Credit" && paymentDate==="" ){
      toast.error("Payment Date is mandatory");
      return;

    }
    if(receivedAmount==="" ){
      toast.error("Payment amount is mandatory");
      return;

    }

    try {
        await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/sales/updatePayment`, {
        id: entry.id,
        paymentStatus,
        paymentDate,
        receivedAmount,
      });   
      alert('Payment details updated successfully!');
      onUpdate(entry.id, paymentStatus, paymentDate, receivedAmount);
      onClose();
    } catch (error) {
      alert('Failed to update payment details.');
      console.error('Failed to update payment details:', error);
    }
  };

  const submitButtonClass = paymentStatus === 'Received' ? styles.received : paymentStatus === 'Pending' ? styles.pending : '';
  console.log("entry",entry)
  return (
    
    <div className={styles['modal']}>
       <ToastContainer position="bottom-right" autoClose={5000} />
      <div className={styles['modal-content']}>
        <h2>Update Payment Details</h2>
        <hr></hr>
        <div>
          <div  className={styles['form-group']}>
          <label htmlFor='paymenttype'>Payment Type:</label> <span>{ entry?.PaymentMethod || 'NA' }</span>
          </div>
          <label htmlFor="paymentStatus">Payment Status:</label>
          
          <select
            id="paymentStatus"
            type="text"
            value={paymentStatus}
            onChange={(e) => setPaymentStatus(e.target.value)}
          >
            <option value="">Select Status</option>
            <option value="Received">Received</option>
            <option value="Pending">Pending</option>
          </select>
        </div>
        <div>
          <label htmlFor="paymentDate">Payment Date:</label>
          <input
            id="paymentDate"
            type="date"
            value={paymentDate}
            onChange={(e) => setPaymentDate(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="receivedAmount">Received Amount:</label>
          <input
            id="receivedAmount"
            type="number"
            value={receivedAmount}
            onChange={(e) => setReceivedAmount(e.target.value)}
          />
        </div>
        {!isReadOnly &&( <button onClick={handlePaymentSubmit} className={`${styles.submit} ${submitButtonClass}`} >Submit</button>)}
        <button onClick={onClose} className={styles['cancel']} >Cancel</button>
      </div>
    </div>
  );
};

export default UpdatePaymentModal;
