import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SampleModal from './component/SampleModal';


import styles from './HospitalPortal.module.css';
import TestStatusModal from './component/TestStatusModal';
import HospitalEmailModal from './HospitalEmailModal';
import TestResultModal from './component/TestResultModal';
import {generateTRFDocument} from '../src/SalesDetailsPage';

function HospitalPortal() {
  const [tests, setTests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sampleModalIsOpen, setSampleModalIsOpen] = useState(false);
  const [testStatusModalIsOpen, setTestStatusModalIsOpen] = useState(false);
  const [TestResultModalIsOpen,setTestResultModalIsOpen]=useState(false);
  const [selectedTest, setSelectedTest] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  const [HospitalemailModalIsOpen, setHospitalEmailModalIsOpen] = useState(false);
  const [relatedTests, setRelatedTests] = useState([]);
  
 

  useEffect(() => {
    const fetchTests = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/lab/tests`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        const sortedTests = response.data.sort((a, b) => b.salesEntryId - a.salesEntryId); // Sort by transaction ID in descending order
        setTests(sortedTests);
        setLoading(false);
      } catch (error) {
        setError(error.response ? error.response.data.message : error.message);
        setLoading(false);
      }
    };

    fetchTests();
  }, []);

  const fetchTestsBySalesEntryId = async (salesEntryId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/lab/patienttest?salesEntryId=${salesEntryId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      return response.data; // Return the tests related to the salesEntryId
    } catch (error) {
      console.error("Failed to fetch related tests", error);
      setError(error.response ? error.response.data.message : error.message);
      return [];
    }
  };

  const handleSampleReceivedStatus = async (testId, { status, date, time,expectedReportDate,rejectionReason }) => {
   
    try {
        
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/lab/tests/${testId}/sample-received`, 
      { sampleReceivedStatus: status, sampleReceivedDate: date, sampleReceivedTime: time ,expectedReportDate:expectedReportDate,rejectionReason:rejectionReason}, 
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      // Update the state to reflect the new status
      setTests(prevTests => prevTests.map(test => test.id === testId ? { ...test, sampleReceivedStatus: status, sampleReceivedDate: date, sampleReceivedTime: time,expectedReportDate:expectedReportDate } : test));
    } catch (error) {
      console.error("Failed to update sample received status", error);
      setError(error.response ? error.response.data.message : error.message);
    }
  };

  const handleTestStatus = async (testId, { status, rejectionReason,resonforReupload}) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/lab/tests/${testId}/test-status`, 
      { testStatus: status, rejectionReason,resonforReupload }, 
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      // Update the state to reflect the new status
      setTests(prevTests => prevTests.map(test => test.id === testId ? { ...test, testStatus: status, rejectionReason } : test));
    } catch (error) {
      console.error("Failed to update test status", error);
      setError(error.response ? error.response.data.message : error.message);
    }
  };

  const handleFileUpload = async (testId, file) => {
    const formData = new FormData();
    formData.append('report', file);

    try {
      const response=await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/lab/tests/${testId}/upload-report`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      
      console.log("File uploaded successfully");
      return response.data.filePath;
    } catch (error) {
      console.error("Failed to upload report", error);
      setError(error.response ? error.response.data.message : error.message);
    }
  };

  const handleSampleResult = async (testId,  { result, values, antibodies }) => {
 
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/api/lab/tests/${testId}/testResult`,
        { result,values,antibodies },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      console.log("Sample result updated:", response.data);
      
      // Update the state to reflect the new result
      setTests(prevTests =>
        prevTests.map(test => 
          test.id === testId ? { ...test, testResult: result, values, antibodies } : test
        )
      );
    } catch (error) {
      console.error("Failed to update result", error);
      setError(error.response ? error.response.data.message : error.message);
    }
  };


  const handleDownloadTRF = async (salesEntryId) => {
    console.log("salesID",salesEntryId)
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/sales/trf/${salesEntryId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      const entry = response.data;
      console.log("entrysales",entry)
      generateTRFDocument(entry);
    } catch (error) {
      console.error('Failed to fetch transaction details:', error);
      alert('Failed to download TRF. Please try again.');
    }
  };

  const openTestResultModal=(test, readOnly)=>{
    setSelectedTest(test);
    setReadOnly(readOnly);
    setTestResultModalIsOpen(true);

  }
  const closeTestResultModal=(test)=>{
    setSelectedTest(null);
    setTestResultModalIsOpen(false);
  }

  const openSampleModal = (test, readOnly) => {
    // console.log("params",test,"readonly", readOnly)
    setSelectedTest(test);
    setReadOnly(readOnly);
    setSampleModalIsOpen(true);
  };

  const openTestStatusModal = (test, readOnly) => {
    setSelectedTest(test);
    setReadOnly(readOnly);
    setTestStatusModalIsOpen(true);
  };
  const openHospitalEmailModal = async (test) => {
    setSelectedTest(test);
    const relatedTests = await fetchTestsBySalesEntryId(test.salesEntryId);
    console.log("relatedTests",relatedTests)
    setRelatedTests(relatedTests);
    setHospitalEmailModalIsOpen(true);
  };

  const closeSampleModal = () => {
    setSelectedTest(null);
    setSampleModalIsOpen(false);
  };

  const closeTestStatusModal = () => {
    setSelectedTest(null);
    setTestStatusModalIsOpen(false);
  };
  const closeHospitalEmailModal = () => {
    setSelectedTest(null);
    setHospitalEmailModalIsOpen(false);
  };

  const handleSendEmail = async (recipient,test,reportUrl) => {
    // setLoading(true);
    console.log("recipient",recipient,test,reportUrl)
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/lab/tests/${selectedTest.id}/send-hospital-email`, {
        recipient,reportUrl,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      console.log("response email",response)
      
      if(response.data.message==="Email sent Successfully"){
        alert("Email sent successfully");
      } 


      console.log("Email sent successfully");
    } catch (error) {
      console.error('Failed to send email:', error);
      alert('Failed to send email Please Check the email entered');
  }
  finally {
    setLoading(false); // Stop the loader in both success and error cases
    // setHospitalEmailModalIsOpen(false);
  }
  };

 


  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className={`${styles.hospitalPortalContainer} hospital-portal`}>
      <h2>Hospital Portal</h2>
      <table>
        <thead>
          <tr>
            <th>Transaction ID</th>
            <th>Patient Name</th>
            <th>Test</th>
            <th>Test Received Status</th>
            <th>TRF</th>
            <th>Test Report</th>
            <th>Result</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {tests.map(test => (
            <tr key={test.id}>
              <td>{test.salesEntryId}</td>
              <td>{test.SalesEntry.PatientFullName}</td>
              <td>{test.test}</td>
              <td>
                <button onClick={() => openSampleModal(test, test.sampleReceivedStatus === "Received & Accepted" || test.sampleReceivedStatus === "Received & Rejected")}
                 className={
                  styles[
                    test.sampleReceivedStatus === "Received & Accepted" 
                      ? "accepted" 
                      : test.sampleReceivedStatus === "Received & Rejected" 
                        ? "rejected" 
                        : "pending"
                  ]
                }
                  
                  >
                  {test.sampleReceivedStatus === "Received & Accepted" ? "Received & Accepted" :test.sampleReceivedStatus === "Received & Rejected" ? "Received & Rejected" : "Pending"}
                </button>
              </td>
              <td>
                <button  className={styles['download-button']} onClick={()=>handleDownloadTRF(test.salesEntryId)}>Download</button>
                </td>
                <td style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <button 
                      onClick={() => openTestStatusModal(test, test.testStatus === "Accepted")}
                      className={
                        styles[
                          test.testStatus === "Accepted" ? "accepted" : 
                          test.testStatus === "Rejected" ? "rejected" : 
                          "pending"
                        ]
                      }
                    >
                      {test.testStatus === "Accepted" ? "Accepted" : test.testStatus === "Rejected" ? "Rejected" : "Pending"}
                    </button>
              </td>
              <td >
                
              <button onClick={()=>openTestResultModal(test, test.testResult==="Positive"||test.testResult==="Negative")}
                className={
                  styles[test.testResult==="Positive"?"accepted":
                    test.testResult==="Negative"?"rejected":
                    "pending"
                  ]
                }
                style={{paddingLeft:"0px",paddingRight:"0px", display:'inline-flex'}}
                
                >
                {test.testResult==="Positive"?"Positive":test.testResult==="Negative"?"Negative":"Enter Result"}
              </button>
              </td>
              <td>
              <button onClick={() => openHospitalEmailModal(test)}>Send Email</button>
                
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedTest && (
        <SampleModal
          isOpen={sampleModalIsOpen}
          onRequestClose={closeSampleModal}
          test={selectedTest}
          handleSampleReceivedStatus={handleSampleReceivedStatus}
          readOnly={readOnly}
        />
      )}
      {selectedTest && (
        <TestStatusModal
          isOpen={testStatusModalIsOpen}
          onRequestClose={closeTestStatusModal}
          test={selectedTest}
          handleTestStatus={handleTestStatus}
          handleFileUpload={handleFileUpload}
          readOnly={readOnly}
        />
      )}
        {selectedTest && relatedTests.length > 0 && (
        <HospitalEmailModal
          isOpen={HospitalemailModalIsOpen}
          onRequestClose={closeHospitalEmailModal}
          // handleSendEmail={handleSendEmail}
          test={relatedTests}
          SendEmail={handleSendEmail}
        />
      )}

      {selectedTest && (
        <TestResultModal
          isOpen={TestResultModalIsOpen}
          onRequestClose={closeTestResultModal}
          test={selectedTest}
        
          handleSampleResult={handleSampleResult}
        />
      )}
    </div>
  );
}

export default HospitalPortal;
