// src/LoginPage.js
import React, { useState } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./LoginPage.css";
import { useNavigate } from "react-router-dom"; // Import useNavigate
// import Carousel from "react-bootstrap/Carousel"; // Import Carousel from Bootstrap
import { ToastContainer, toast, cssTransition } from "react-toastify";


function LoginPage({ onLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // State for error messages
  const [successMessage, setSuccessMessage] = useState(""); // State for success messages
  const [showPassword, setShowPassword] = useState(false); //show password

  const navigate = useNavigate(); // Initialize navigate function

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage(""); // Clear previous error message
    try {
      // Attempt to log in
      await onLogin(username, password);
      // setSuccessMessage("Login successful!");
      toast.success("Login successful!");

      const userRole = localStorage.getItem("role");

      // Navigate based on user role
      if (userRole === "lab") {
        navigate("/hospital-portal");
      } else if (userRole === "sales_rep") {
        navigate("/sales-details");
      } else if (userRole === "admin") {
        navigate("/dashboardPage");
      }
    } catch (error) {
      let errorMsg = "Login failed. Please try again."; // Default error message
      if (error.response && error.response.data.message) {
        errorMsg = error.response.data.message;
      } else if (error.request) {
        errorMsg = "Network error. Please check your connection.";
      }
      toast.error(errorMsg);
      // setErrorMessage(errorMsg);
    }
  };

  return (
    <div className="login-container">
      <div className="login-left">
      <img
              style={{width:"650px"}}
              src="https://crm-zenix-images.s3.ap-south-1.amazonaws.com/crm-5.webp" // Replace with CRM-related images
              alt="Third slide"
        />
       
      </div>
      <div className="login-right" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <ToastContainer position="bottom-right" autoClose={5000} />
      {/* <div >
        <img 
        style={{width:"200px"}}
        src="https://crm-zenix-images.s3.ap-south-1.amazonaws.com/z+logo.png"/>
      </div> */}
      <div>
        <form onSubmit={handleSubmit} className="login-form">
        <img 
        style={{width:"200px"}}
        src="https://crm-zenix-images.s3.ap-south-1.amazonaws.com/z+logo.png"/>
          {/* <h2 className="login-title">ZeiniX CRM-Login</h2> */}
          {successMessage && (
            <div className="feedback success">{successMessage}</div>
          )}
          {errorMessage && (
            <div className="feedback error">{errorMessage}</div>
          )}
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <input
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <div className="show-password">
            <input
              type="checkbox"
              checked={showPassword}
              onChange={() => setShowPassword(!showPassword)}
            />
            <label>Show Password</label>
          </div>
          <button type="submit" className="login-button">
            Login
          </button>
        </form>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
