import React from 'react';
import './SalesOverviewCard.css'; // Ensure to create a corresponding CSS file for styling

const SalesOverviewCard = ({ totalSales, totalTests, totalAmount }) => {
  return (
    <div className="sales-overview-card">
      <div className="main-content">
        <div className="title">Total Sales</div>
        <div className="value">{totalSales} Sales</div>
      </div>
      <div className="sub-content-container">
        <div className="sub-content-left">
          <div>Total Amount</div>
          <div>₹{totalAmount.toFixed(2)}</div>
        </div>
        <div className="sub-content-right">
          <div>Total Tests</div>
          <div>{totalTests} Tests</div>
        </div>
      </div>
    </div>
  );
};

export default SalesOverviewCard;
