// CongratulationModal.js
import React from 'react';
import './CongratulationModal.css';

const CongratulationModal = ({ show, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="congrats-modal">
      <div className="congrats-modal-content">
        <h2>Congratulations!</h2>
        <p>Your form has been submitted successfully.</p>
          <p>  You will be Redirected to Instructions Page Up on Clicking Close Button.</p>
        <button onClick={onClose} className="congrats-modal-button">Close</button>
      </div>
    </div>
  );
};

export default CongratulationModal;
