import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SalesDetailsPage.css';
import { jsPDF } from "jspdf";
import UpdatePaymentModal from './component/UpdatePaymentModal';
import styles from './component/emailpreview.module.css';
import { ToastContainer, toast, cssTransition } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



const userTypes = [
  'Select',
  'Thru Sales Guys',
  'Direct /Zydus / North India Medical Colleges',
  "St.John's / NIMS / NIMHANS/JIPMER /South India Medical Colleges",
  "Dr.Nair's",
  'Belle Vue Hosp',
  'Mercury',
  'RF20',
  'RF10',
  'Brains Hosp'
];


const convertToBase64 = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous'; // This is crucial for CORS
    img.src = url;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL('image/png');
      resolve(dataURL);
    };
    img.onerror = (error) => reject(error);
  });
};

export const generateTRFDocument = async (entry) => {
  const doc = new jsPDF('p', 'mm', 'a4'); //potrait,dimension in mm and size A4

  // Define initial position and dimensions
  let startX = 10;
  let startY = 15;
  const lineHeight = 4;
  const boxHeight = lineHeight * 1.6;
  const boxWidth = 190;
  const halfBoxWidth = (boxWidth / 2) - 5;
  const textPadding = 5;

  const logoUrl = 'https://crm-zenix-images.s3.ap-south-1.amazonaws.com/image+(49).png';

  try {
    const logoBase64 = await convertToBase64(logoUrl);
 
    doc.addImage(logoBase64, 'PNG', 175, 5, 28, 28); // Position at (175, 5), size (28x28)
  } catch (error) {
    console.error('Failed to load image:', error);
    // Handle the error appropriately (e.g., display a placeholder image, or skip adding the image)
  }

// Use the jsPDF 'addImage' method to add the logo
// doc.addImage(logoUrl, 'PNG', 175, 5, 28, 28); // Position at (160, 10), size (30x30). Adjust as needed.


  // Add title and subtitle
  doc.setFontSize(14);
  doc.setFont('helvetica', 'bold');
  doc.text("ZeiniX AIN TRF", startX, startY, null, null);

  startY += lineHeight + 2;

  doc.setFontSize(10);
  doc.setFont('helvetica', 'normal');
  doc.text("Test Request Form For Ordering Autoimmune Neurology Diagnostics", startX, startY, null, null);
  startY += lineHeight + 5;

  // Add institution and notes
  doc.setFontSize(9);
  doc.setFont('helvetica', 'italic');
  doc.text("Sample Sent Through ZeiniX Life Sciences", startX, startY);
  doc.text("Tested At: Nalanda 7th Floor, Amrita Institute of Medical Sciences & Research Centre (AIMS), Kochi, Kerala, India", startX, startY + lineHeight);
  startY += lineHeight * 2.5;

  doc.setFont('helvetica', 'bold');
  doc.text("* Important", startX, startY);
  startY += lineHeight * 0.8;
  doc.setFont('helvetica', 'normal');
//  drawBox( doc.text("Please enter patient name exactly as needed in the report / invoice. Once submitted, name cannot be changed.", startX, startY + lineHeight);
//   doc.text("Please ensure the name in this TRF & sample tube are exactly same - otherwise sample will get rejected.", startX, startY + (lineHeight * 2));
 
//  );

// Define the text for the important message
const importantText1 = "Please enter patient name exactly as needed in the report / invoice. Once submitted, name cannot be changed.";
const importantText2 = "Please ensure the name in this TRF & sample tube are exactly same - otherwise sample will get rejected.";
const importantText = importantText1 + "\n" + importantText2;
// Calculate the box height based on the number of lines
const importantLines = doc.splitTextToSize(importantText, boxWidth - (textPadding * 1));
const importantBoxHeight = (importantLines.length * lineHeight) + (textPadding * 1);
doc.setFont('helvetica', 'normal');
drawBox(doc, startX, startY, boxWidth, importantBoxHeight, importantText);

startY += importantBoxHeight + 4; // Move startY down for the next section

doc.setFont('helvetica', 'bold');
  doc.setFontSize(8);
  doc.text("AIMS Form Control Number FO-NEIM-001", startX, startY);
  startY += lineHeight + 5;

  // Patient Information
  doc.setFontSize(12);
  doc.setFont('helvetica', 'bold');
  doc.text("Patient Information", startX, startY);
  startY += lineHeight;
  doc.setFontSize(8);
  // doc.setFont('helvetica', 'normal');
  drawBox(doc, startX, startY, boxWidth, boxHeight, `Patient Name: ${entry.PatientFullName || 'N/A'}`);
  startY += boxHeight + 4;
  
  drawBox(doc, startX, startY, halfBoxWidth, boxHeight, `Patient Age: ${entry.PatientAge || 'N/A'}`);
  drawBox(doc, startX + halfBoxWidth + 10, startY, halfBoxWidth, boxHeight, `Patient Date of Birth: ${entry.PatientDateOfBirth || 'N/A'}`);
  startY += boxHeight + 4;

  drawBox(doc, startX, startY, halfBoxWidth, boxHeight, `Patient Mobile: ${entry.PatientMobileNumber || 'N/A'}`);
  drawBox(doc, startX + halfBoxWidth + 10, startY, halfBoxWidth, boxHeight, `Patient Email: ${entry.PatientEmailID || 'N/A'}`);
  startY += boxHeight + 4;
  drawBox(doc, startX, startY, halfBoxWidth, boxHeight, `Patient Gender: ${entry.PatientGender || 'N/A'}`);
  startY += boxHeight + 10;

  // Physician Information
  doc.setFontSize(12);
  doc.setFont('helvetica', 'bold');
  doc.text("Physician Information", startX, startY);
  startY += lineHeight;
  doc.setFontSize(8);
  drawBox(doc, startX, startY, halfBoxWidth, boxHeight, `Primary Physician: ${entry.ReferringConsultantName_PrimaryPhysician || 'N/A'}`);
  drawBox(doc, startX + halfBoxWidth + 10, startY, halfBoxWidth, boxHeight, `Hospital ID: ${entry.PatientHospitalID || 'N/A'}`);
  startY += boxHeight + 5;

  drawBox(doc, startX, startY, halfBoxWidth, boxHeight, `Primary Physician Mob No.: ${entry.PrimaryPhysicianMobileNumber || 'N/A'}`);
  drawBox(doc, startX + halfBoxWidth + 10, startY, halfBoxWidth, boxHeight, `Primary Physician Email: ${entry.PrimaryPhysicianEmailId || 'N/A'}`);
  startY += boxHeight + 5;

  drawBox(doc, startX, startY, halfBoxWidth, boxHeight, `Alternate Physician: ${entry.AlternatePhysician || 'N/A'}`);
  drawBox(doc, startX + halfBoxWidth + 10, startY, halfBoxWidth, boxHeight, `Alternate Physician Mob No.: ${entry.AlternatePhysicianMob || 'N/A'}`);
  startY += boxHeight + 5;

  drawBox(doc, startX, startY, halfBoxWidth, boxHeight, `Alternate Physician Email: ${entry.AlternatePhysicianEmail || 'N/A'}`);
  drawBox(doc, startX + halfBoxWidth + 10, startY, halfBoxWidth, boxHeight, `Hospital / Clinic: ${entry.HospitalName || 'N/A'}`);
  startY += boxHeight + 5;

  drawBox(doc, startX, startY, halfBoxWidth, boxHeight, `Hospital Email: ${entry.HospitalEmail || 'N/A'}`);
  drawBox(doc, startX + halfBoxWidth + 10, startY, halfBoxWidth, boxHeight, `City: ${entry.City || 'N/A'}`);
  startY += boxHeight + 10;

  // Clinical Details
  doc.setFontSize(12);
  doc.setFont('helvetica', 'bold');
  doc.text("Clinical Details / Provisional Diagnosis", startX, startY);
  startY += lineHeight;
  drawBox(doc, startX, startY, boxWidth, boxHeight * 2, `${entry.ClinicalDetails || 'N/A'}`);
  startY += boxHeight * 2 + 10;

  // Sample Details
  doc.setFontSize(12);
  doc.setFont('helvetica', 'bold');
  doc.text("Sample Details", startX, startY);
  startY += lineHeight;
  doc.setFontSize(8);
  drawBox(doc, startX, startY, halfBoxWidth, boxHeight, `Sample Sent: ${entry.SampleSent || 'N/A'}`);
  drawBox(doc, startX + halfBoxWidth + 10, startY, halfBoxWidth, boxHeight, `No. of Samples: ${entry.NoOfSampleSent || 'N/A'}`);
  startY += boxHeight + 5;

 

  drawBox(doc, startX, startY, halfBoxWidth, boxHeight, `No. of Tests: ${entry.tests.length || 'N/A'}`);
  startY += boxHeight + 5;
  // const testsArray = Array.isArray(entry.tests) ? entry.tests : [];
  const testsArray=entry.tests;
  console.log("testsArray",entry.tests)
  // const validTests = testsArray.filter(test => test !== undefined && test !== null);
  
  testsArray.forEach((test, index) => {
    // Combine test name and sample information into a single string
    const testInfo = `Test ${index + 1}: ${testsArray[index].test || 'N/A'}, Sample: ${testsArray[index].sample || 'N/A'}`;
  
    // Draw the combined information in a single box
    drawBox(doc, startX, startY, boxWidth, boxHeight, testInfo);
  
    // Update startY for the next test
    startY += boxHeight + 3; // Adjust space between boxes as needed
  });

  // Footer Information
  doc.setFontSize(9);
  doc.setFont('helvetica', 'normal');
  doc.text("Sample Collection Date: 03-04-2024 Sample Collection Time: 12:00:00", startX, startY);
  startY += lineHeight + 2;
  doc.text("Form Filled By: Referring Dr / Alternate Physician Form Filled By: Dr Thomas Mathew", startX, startY);

  // Save the document
  doc.save(`TRF-${entry.PatientFullName ||entry.HospitalName||"Zeinix"}.pdf`);
};

// Helper function to draw boxed text areas
const drawBox = (doc, x, y, width, height, text) => {
  doc.setLineWidth(0.2);
  doc.rect(x, y, width, height);
  const lines = doc.splitTextToSize(text, width - 4);
  doc.text(lines, x + 2, y + 5);
};




const SalesDetailsPage = () => {
  const [salesEntries, setSalesEntries] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [selectedEntryForEmail, setSelectedEntryForEmail] = useState(null);
  const [selectedEntryForPayment, setSelectedEntryForPayment] = useState(null);
  const [editableEntryId, setEditableEntryId] = useState(null);
  const [emailHTML, setEmailHTML] = useState('');
  const [loading, setLoading] = useState(false);
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);

  const [userType, setUserType] = useState('');
  const [selectedEntryId, setSelectedEntryId] = useState(null);
  const userRole=localStorage.getItem('role');
  const [testlist, settestlist]=useState([]);
  const [searchQuery, setSearchQuery] = useState('');


  useEffect(() => {
    const fetchSalesEntries = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/sales`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, // Ensure you're sending the token
          }
        });
        setSalesEntries(response.data);
        console.log(response.data);
      } catch (error) {
        console.error('Failed to fetch sales entries:', error);
      }
    };

    fetchSalesEntries();
  }, []);

  const regionalCodesfromlocal = JSON.parse(localStorage.getItem('regionalCodes') || '[]');
  useEffect(()=>{

    const fetchtestlist=async()=>{
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/testlist`
      );
      settestlist(response.data);
      console.log("testlist", response.data);
    } catch (error) {
      console.error("Failed to fetch Testlist:", error);
      toast.error("Failed to fetch Testlist");
    }
  //  else {
  //   settestlist([]);
  // }

}
fetchtestlist();
  },[]);
  const filteredData = salesEntries.filter((item) => {
    const rowText = `${item.PatientFullName} ${item.PatientMobileNumber} ${item.PatientAddress}`.toUpperCase();
    return rowText.includes(searchQuery.toUpperCase());
  });

  useEffect(() => {
    const fetchSalesEntriesbyregionalID = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/sales/${regionalCodesfromlocal}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`, // Ensure you're sending the token
          }
        });
        setSalesEntries(response.data);
        console.log("Resonse",response)
      } catch (error) {
        console.error('Failed to fetch sales entries:', error);
      }
    };

    fetchSalesEntriesbyregionalID();
  }, []);

  const handleOpenModal = (entry) => {
    console.log("sendemail1",entry)
    console.log("selectedEntryForEmail",)
    setSelectedEntryForEmail(entry);
    fetchEmailPreview(entry);
    
  
    setIsModalOpen(true);
  };
  const handleInvoiceModal=(entry)=>{
    setSelectedEntryId(entry.id);
    setSelectedEntry(entry);
    console.log("entry for invoice",entry)

    setIsInvoiceModalOpen(true);
  }

  const fetchEmailPreview = async (entry) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/sales/sendEmailPreview`, entry);
      setEmailHTML(response.data.html);
    } catch (error) {
      console.error('Failed to fetch email preview:', error);
      setEmailHTML('<p>Failed to load email preview.</p>');
    }
  };

  const sendEmail = async () => {
    try {

      setLoading(true);
      // send email URL
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/sales/sendEmail`, selectedEntryForEmail);
      console.log("selectedEntryForEmail", selectedEntryForEmail);
      alert('Email sent successfully!');
      setIsModalOpen(false); // Close the modal
    } catch (error) {
      alert('Failed to send email.');
      console.error('Failed to send email:', error);
    }
    setLoading(false);
  };

  const handleOpenPaymentModal = (entry) => {
    setSelectedEntryForPayment(entry);
    setIsPaymentModalOpen(true);
  };

  const updatePaymentDetails = (id, paymentStatus, paymentDate, receivedAmount) => {
    setSalesEntries((prevEntries) =>
      prevEntries.map((entry) =>
        entry.id === id
          ? { ...entry, PaymentStatus: paymentStatus, PaymentDate: paymentDate, PaymentAmount: receivedAmount }
          : entry
      )
    );
  };

  
  const formatTests = (tests) => {
    return tests.map(test => `Test: ${test.test}, Sample: ${test.sample}`).join('<br/>');
  };

  const handleEdit = (id) => {
    setEditableEntryId(id);
  };




  const handleTestChange = (entryId, index, field, value) => {
    setSalesEntries((prevEntries) =>
      prevEntries.map((entry) =>
        entry.id === entryId
          ? {
              ...entry,
              tests: entry.tests.map((test, i) =>
                i === index ? { ...test, [field]: value } : test
              ),
            }
          : entry
      )
    );
  };
  
  const handleAddTest = (entryId) => {
    setSalesEntries((prevEntries) =>
      prevEntries.map((entry) =>
        entry.id === entryId
          ? {
              ...entry,
              tests: [...entry.tests, { test: "", sample: "", salesEntryId: entryId }],
            }
          : entry
      )
    );
  };
  
  const handleRemoveTest = (entryId, index) => {
    setSalesEntries((prevEntries) =>
      prevEntries.map((entry) =>
        entry.id === entryId
          ? {
              ...entry,
              tests: entry.tests.filter((_, i) => i !== index),
            }
          : entry
      )
    );
  };
  
  const handleSave = async (entry) => {
    try {
      const { tests, ...entryData } = entry;
     
       entryData.TransactionStatus = 'Approved'
      console.log("entryData",entry)
  
      await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/sales/${entry.id}`, entryData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
  
      // Update tests
      for (const test of tests) {
        if (test.id) {
          console.log("testid",test.id)
          await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/sales/tests/${test.id}`, test, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
        } else {
          await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/api/sales/tests`,
            {
              ...test,
              salesEntryId: entry.id,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
              },
            }
          );
        }
      }
      setSalesEntries((prevEntries) =>
        prevEntries.map((prevEntry) =>
          prevEntry.id === entry.id
            ? { ...prevEntry, ...entryData, TransactionStatus: 'Approved' }
            : prevEntry
        )
      );
  
      alert('Entry updated successfully!');
      setEditableEntryId(null); // Close edit mode
    } catch (error) {
      alert('Failed to update entry.');
      console.error('Failed to update entry:', error);
    }
  };

  const handleGenerateInvoice = async (patientfullname) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/sales/generate-invoice/${selectedEntryId}`,
        {userType,},
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Accept': 'application/pdf', // Expect a PDF response
          },
          responseType: 'blob' // Set the response type to blob for binary data
        }
      );

      // Create a link to download the PDF
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const today = new Date();
      const formattedDate = `${String(today.getDate()).padStart(2, '0')}-${String(today.getMonth() + 1).padStart(2, '0')}-${today.getFullYear()}`;
      
      link.setAttribute('download', `ZX_${selectedEntry.PatientFullName}_${formattedDate}.pdf`);
      
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error generating invoice:', error);
      alert('Failed to generate invoice. Please try again.');
    }
    setLoading(false);
  };

  // const handleSave = async (entry) => {
  //   try {
  //     await axios.put(`http://localhost:3000/api/sales/${entry.id}`, entry, {
  //       headers: {
  //         'Authorization': `Bearer ${localStorage.getItem('token')}`,
  //       }
  //     });
  //     alert('Entry updated successfully!');
  //     setEditableEntryId(null); // Close edit mode
  //   } catch (error) {
  //     alert('Failed to update entry.');
  //     console.error('Failed to update entry:', error);
  //   }
  // };

  const handleChange = (id, field, value) => {
    setSalesEntries((prevEntries) =>
      prevEntries.map((entry) =>
        entry.id === id ? { ...entry, [field]: value } : entry
      )
    );
  };

//   useEffect(() => {
//     console.log("into useeffect of send email")
//     console.log("params",isModalOpen,selectedEntryForEmail)
//     if (isModalOpen && selectedEntryForEmail) {
//         // Fetch the email content from the backend
//         axios.post('http://localhost:3000/api/sales/sendEmail', selectedEntryForEmail)
//             .then(response => {
//                 setEmailHTML(response.data.html);
//             })
//             .catch(error => {
//                 console.error('Failed to fetch email preview:', error);
//                 setEmailHTML('<p>Failed to load email preview.</p>');
//             });
//     }
// }, [isModalOpen, selectedEntryForEmail]);

  return (
    <div className="sales-details-container">
      <ToastContainer position="bottom-right" autoClose={5000} />

      <h2 style={{ paddingTop: "6%" }}>Sales Details</h2>
      {loading && <div className="loader"></div>}
      <div className="search-container">
        {/* <label htmlFor="searchInput">Search</label> */}
        <input
          type="text"
          id="searchInput"
          placeholder="Search for names..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)} // Use React's onChange event
        />
      </div>
      <main className="table" id="sales_table">
        <div className="table-container">
          <div className="table__body">
            <table className="sales-table" id="dataTable">
              <thead>
                <tr>
                  <th>Patient Name</th>
                  <th>Number</th>
                  <th>Address</th>
                  <th>Details</th>
                  <th>User ID</th>
                  <th>Patient Gender</th>
                  <th>Age</th>
                  <th>Patient DOB</th>
                  <th>Patient Email</th>
                  <th>Hospital Id</th>
                  <th>Primary Physician</th>
                  <th>Primary Physician Mobile Number</th>
                  <th>Primary Physician Email Id</th>
                  <th>Refering doctor Name(Alternative Physician)</th>
                  <th>Alternative Physician Mobile No.</th>
                  <th>Alternative Physician Email Id</th>
                  <th>Hospital/Clinic Name</th>
                  <th>Hospital/Clinical Email Id</th>
                  <th>City</th>
                  <th>Clinical Details of Patient/Patient History, Provisional Diagnosis</th>
                  <th className="test-details-column">Test Details</th>
                  <th>Sample Collection Date and Time</th>
                  <th>Form Filled By</th>
                  <th>Name of Form Filler</th>
                  <th>Sample Received Status</th>
                  <th>Received by</th>
                  <th>Amount (₹)</th>
                  <th>Pick Up Address</th>
                  <th>Pick Up City</th>
                  <th>Pick Up City Pincode</th>
                  <th>Contact Person Name</th>
                  <th>Contact Person Number</th>
                  <th>Pick Up Date</th>
                  
                  {(userRole==="admin" 
                    ||userRole==="sales_rep") && <th>Payment Details</th>}     
                  <th>Status</th>
                 {userRole==="admin"&& <th>Actions</th>}
                 <th>Email to Doctor/Patient</th>
                 {(userRole==="admin")&&<th>Invoice</th>}
                </tr>
              </thead>
              <tbody>
            {filteredData.map((entry) => (
              <tr key={entry.id}>
                {editableEntryId === entry.id ? (
                    <>
                    <td><input type="text" value={entry.PatientFullName} onChange={(e) => handleChange(entry.id, 'PatientFullName', e.target.value)} /></td>
                    <td><input type="text" value={entry.PatientMobileNumber} onChange={(e) => handleChange(entry.id, 'PatientMobileNumber', e.target.value)} /></td>
                    <td><input type="text" value={entry.PatientAddress} onChange={(e) => handleChange(entry.id, 'PatientAddress', e.target.value)} /></td>
                    <td><input type="text" value={entry.PatientDetails} onChange={(e) => handleChange(entry.id, 'PatientDetails', e.target.value)} /></td>
                    <td>{entry.UserID}</td>
                    <td><input type="text" value={entry.PatientGender} onChange={(e) => handleChange(entry.id, 'PatientGender', e.target.value)} /></td>
                    <td><input type="number" value={entry.PatientAge} onChange={(e) => handleChange(entry.id, 'PatientAge', e.target.value)} /></td>
                    <td><input type="date" value={entry.PatientDateOfBirth} onChange={(e) => handleChange(entry.id, 'PatientDateOfBirth', e.target.value)} /></td>
                    <td><input type="text" value={entry.PatientEmailID} onChange={(e) => handleChange(entry.id, 'PatientEmailID', e.target.value)} /></td>
                    <td><input type="text" value={entry.PatientHospitalID} onChange={(e) => handleChange(entry.id, 'PatientHospitalID', e.target.value)} /></td>
                    <td><input type="text" value={entry.ReferringConsultantName_PrimaryPhysician} onChange={(e) => handleChange(entry.id, 'ReferringConsultantName_PrimaryPhysician', e.target.value)} /></td>
                    <td><input type="text" value={entry.PrimaryPhysicianMobileNumber} onChange={(e) => handleChange(entry.id, 'PrimaryPhysicianMobileNumber', e.target.value)} /></td>
                    <td><input type="text" value={entry.PrimaryPhysicianEmailId} onChange={(e) => handleChange(entry.id, 'PrimaryPhysicianEmailId', e.target.value)} /></td>
                    <td><input type="text" value={entry.ReferringDoctorName_AlternatePhysician} onChange={(e) => handleChange(entry.id, 'ReferringDoctorName_AlternatePhysician', e.target.value)} /></td>
                    <td><input type="text" value={entry.AlternativePhysicianMobileNo} onChange={(e) => handleChange(entry.id, 'AlternativePhysicianMobileNo', e.target.value)} /></td>
                    <td><input type="text" value={entry.AlternativePhysicianEmailId} onChange={(e) => handleChange(entry.id, 'AlternativePhysicianEmailId', e.target.value)} /></td>
                    <td><input type="text" value={entry.HospitalClinicName} onChange={(e) => handleChange(entry.id, 'HospitalClinicName', e.target.value)} /></td>
                    <td><input type="text" value={entry.HospitalorClinicalEmailId} onChange={(e) => handleChange(entry.id, 'HospitalorClinicalEmailId', e.target.value)} /></td>
                    <td><input type="text" value={entry.City} onChange={(e) => handleChange(entry.id, 'City', e.target.value)} /></td>
                    <td><input type="text" value={entry.ClinicalDetailsofPatient_PatientHistory_PriviousDiagonosis} onChange={(e) => handleChange(entry.id, 'ClinicalDetailsofPatient_PatientHistory_PriviousDiagonosis', e.target.value)} /></td>
                    {/* <td><input type="text" value={entry.tests ? formatTests(entry.tests) : "No tests available"} onChange={(e) => handleChange(entry.id, 'tests', e.target.value)} /></td> */}
                    <td>
                    {console.log("entrytest",entry.tests)}
                          {entry.tests && entry.tests.map((test, index) => (
                            <div key={index}>
                              <select
                                type="text"
                                value={test.test}
                                onChange={(e) => handleTestChange(entry.id, index, 'test', e.target.value)}
                                style={{ display: 'inline-block', cursor: 'pointer',width:"250px"}}

                              >
                                <option value="">Select Test </option>
                              {testlist.map((test) => (
                            <option key={test.id} value={test.TestName}>
                              {test.TestName}
                            </option>
                              ))}


                                </select>
                              <select
                                type="text"
                                value={test.sample}
                                onChange={(e) => handleTestChange(entry.id, index, 'sample', e.target.value)}
                                style={{ display: 'inline-block', cursor: 'pointer',width:"150px"}}
                              >
                               <option value="">Select Sample</option>
                              <option value="Serum">Serum</option>
                              <option value="CSF">CSF</option>
                              <option value="Both Serum & CSF">Both Serum & CSF</option>
                              </select>
                              <button 
                                      type="button" 
                                      onClick={() => handleRemoveTest(entry.id, index)}
                                      style={{ 
                                        display: 'inline-block', 
                                        cursor: 'pointer', 
                                        padding: '5px', 
                                        backgroundColor: 'red',  // Set background color here
                                        color: 'white'           // Set text color to make it visible
                                      }}                                      
                                    >
                                      Remove
                                    </button>                           
                               </div>
                          ))}
                          <button type="button" onClick={() => handleAddTest(entry.id)}>Add Test</button>
                        </td>
                    <td><input type="text" value={entry.SampleCollectionDateTime} onChange={(e) => handleChange(entry.id, 'SampleCollectionDateTime', e.target.value)} /></td>
                    <td><input type="text" value={entry.FormFilledBy} onChange={(e) => handleChange(entry.id, 'FormFilledBy', e.target.value)} /></td>
                    <td><input type="text" value={entry.NameOfFormFiller} onChange={(e) => handleChange(entry.id, 'NameOfFormFiller', e.target.value)} /></td>
                    <td><input type="text" value={entry.SampleReceivedStatus} onChange={(e) => handleChange(entry.id, 'SampleReceivedStatus', e.target.value)} /></td>
                    <td><input type="text" value={entry.ReceivedBy} onChange={(e) => handleChange(entry.id, 'ReceivedBy', e.target.value)} /></td>
                    <td><input type="number" value={entry.Amount} onChange={(e) => handleChange(entry.id, 'Amount', e.target.value)} /></td>
                   
                    <td><input type="text" value={entry.PickUpAdress} onChange={(e) => handleChange(entry.id, 'PickUpAdress', e.target.value)} /></td>
                    <td><input type="text" value={entry.PickUpCity} onChange={(e) => handleChange(entry.id, 'PickUpCity', e.target.value)} /></td>
                    <td><input type="text" value={entry.PickUpCityPinCode} onChange={(e) => handleChange(entry.id, 'PickUpCityPinCode', e.target.value)} /></td>
                    <td><input type="text" value={entry.ContactPersonName} onChange={(e) => handleChange(entry.id, 'ContactPersonName', e.target.value)} /></td>
                    <td><input type="text" value={entry.ContactPersonNumber} onChange={(e) => handleChange(entry.id, 'ContactPersonNumber', e.target.value)} /></td>
                    <td><input type="text" value={entry.PickUpdate} onChange={(e) => handleChange(entry.id, 'PickUpdate', e.target.value)} /></td>
                    {(userRole==="admin" || userRole ==="sales_rep") && <td>
                      <button onClick={() => handleOpenPaymentModal(entry)}
                        className={entry.PaymentStatus === "Received" ? "received-button" : "pending-button"}>
                        {entry.PaymentStatus === "Received" ? "Received" : "Pending"}
                      </button>
                    </td>}
                    
                    <td>
                      
                      <button style={{ margin: "5px" }} onClick={() => handleSave(entry)}>Save</button>
                    </td>
                    </>
      ) : (
        <>
          <td>{entry.PatientFullName}</td>
          <td>{entry.PatientMobileNumber}</td>
          <td>{entry.PatientAddress}</td>
          <td>{entry.PatientDetails}</td>
          <td>{entry.UserID}</td>
          <td>{entry.PatientGender}</td>
          <td>{entry.PatientAge}</td>
          <td>{entry.PatientDateOfBirth}</td>
          <td>{entry.PatientEmailID}</td>
          <td>{entry.PatientHospitalID}</td>
          <td>{entry.ReferringConsultantName_PrimaryPhysician}</td>
          <td>{entry.PrimaryPhysicianMobileNumber}</td>
          <td>{entry.PrimaryPhysicianEmailId}</td>
          <td>{entry.ReferringDoctorName_AlternatePhysician}</td>
          <td>{entry.AlternativePhysicianMobileNo}</td>
          <td>{entry.AlternativePhysicianEmailId}</td>
          <td>{entry.HospitalClinicName}</td>
          <td>{entry.HospitalorClinicalEmailId}</td>
          <td>{entry.City}</td>
          <td>{entry.ClinicalDetailsofPatient_PatientHistory_PriviousDiagonosis}</td>
          <td dangerouslySetInnerHTML={{ __html: entry.tests ? formatTests(entry.tests) : "No tests available" }} />
          <td>{entry.SampleCollectionDateTime}</td>
          <td>{entry.FormFilledBy}</td>
          <td>{entry.NameOfFormFiller}</td>
          <td>{entry.SampleReceivedStatus}</td>
          <td>{entry.ReceivedBy}</td>
          <td>{entry.Amount}</td>
          <td>{entry.PickUpAdress}</td>
          <td>{entry.PickUpCity}</td>
          <td>{entry.PickUpCityPinCode}</td>
          
          <td>{entry.ContactPersonName}</td>
          <td>{entry.ContactPersonNumber}</td>
          <td>{entry.PickUpdate}</td>
         {(userRole==="admin" || userRole ==="sales_rep")&& <td>
            <button onClick={() => handleOpenPaymentModal(entry)}
              className={entry.PaymentStatus === "Received" ? "received-button" : "pending-button"}>
              {entry.PaymentStatus === "Received" ? "Received" : "Pending"}
            </button>
          </td>}
          <td>
            {entry.TransactionStatus === "Approved" ? "Approved" : (
              <>
                
                <button style={{ margin: "5px" ,backgroundColor: 'yellowgreen'}} onClick={() => handleEdit(entry.id)}>Draft</button>
              </>
            )}
          </td>
          
        </>
      )}
      {userRole==="admin"&& <td>
        <button style={{ margin: "5px" }} onClick={() => handleOpenModal(entry)}>Send Email</button>
        <button onClick={() => generateTRFDocument(entry)}>Generate TRF</button>
      </td>}
      <td >
      <span className={entry.EmailSent ? "email-Sent" : "email-Pending"}>{entry.EmailSent?"Sent":"Pending"}</span>

      </td>
      {(userRole==="admin")&&<td>
                      <button
                        onClick={() => handleInvoiceModal(entry)}
                        disabled={loading}
                        style={{ margin: "5px" }}
                      >
                        Generate Invoice
                      </button>
                    </td>}
              </tr> ))}
              </tbody>
            </table>
          </div>
        </div>
      </main>

      {isModalOpen && (
       
        <div className= "modal" style={{ 
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000
        }}>
          <div className="modal-content" style={{ 
          // width: '90vw',
          maxWidth:'900px',
          maxHeight: '80%',
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '8px',
          overflowY: 'auto',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
        }}>
            <h2 style={{marginTop:"0px"}}>Email Preview</h2>
            <hr></hr>
            <div dangerouslySetInnerHTML={{ __html: emailHTML }} />
            {/* <p>Name: {selectedEntryForEmail.PatientName}</p>
            <p>Patient Details: {selectedEntryForEmail.PatientDetails}</p> */}
             <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
            <button onClick={sendEmail} style={{ padding: '5px', margin: '2px', cursor: loading?"not-allowed":"pointer", opacity:loading?0.6:1 }} disabled={loading}>
            {loading?"Sending Email...": "Confirm & Send"} 
            </button>
            <button onClick={() => setIsModalOpen(false)} style={{ padding: '5px', margin: '2px', backgroundColor:'red' , cursor: loading?"not-allowed":"pointer", opacity:loading?0.6:1 }} disabled={loading}>
              Cancel</button>
              </div>
          </div>
        </div>
      )}

      {isPaymentModalOpen && (
        <UpdatePaymentModal
          entry={selectedEntryForPayment}
          onClose={() => setIsPaymentModalOpen(false)}
          onUpdate={updatePaymentDetails}
          isReadOnly={selectedEntryForPayment.PaymentStatus === 'Received'}
          />
      )}

       {/* Modal for selecting user type */}
       {isInvoiceModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>Select Sales Type</h3>
            <select value={userType} onChange={(e) => setUserType(e.target.value)}>
            {userTypes.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
            <div className="modal-actions">
              <button onClick={handleGenerateInvoice} disabled={!userType || loading}>
                Generate Invoice
              </button>
              <button onClick={() => setIsInvoiceModalOpen(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SalesDetailsPage;
